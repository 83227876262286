<template>
    <v-container class="min-h-full">
        <VTopToolbar title="Participants" :showBack="true"></VTopToolbar>

        <Participants
            v-if="sharedoId"
            :id="sharedoId"
            :showLabel="false"
        />
    </v-container>
</template>

  
<script>
import Participants from "./Participants";

export default {
    components: {
        Participants,
    },
    data() {
        return {
            sharedoId: null,
        };
    },
    mounted() {
        this.sharedoId = this.$route.params.sharedoId;
    },
};
</script>
